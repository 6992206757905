.navbarContainer {
  background-color: transparent;
  height: 58px;
  border-bottom: 1px solid;
  display: flex;
  justify-content: center;
  font-size: large;
}

.navbar {
  padding: 5px;
  height: 48px;
  width: 75%;
  display: flex;
  justify-content: space-between;
  border-radius: var(--b-radius);
}

.navbarLeft {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.navbarImage {
  height: 48px;
  width: 48px;
}

.navbarImageText {
  padding-left: 10px;
  display: none;
}

@media screen and (min-width: 768px) {
  .navbarImageText {
    display: block;
  }
}

.navbarRight {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarThemeButton {
  margin: auto;
}
