.App {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
}

.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.card {
  display: flex;
  padding: 25px;
  margin: 0 5px;
  min-width: 50vw;
  border: 1px solid;
  border-radius: var(--b-radius);
  align-items: center;
  text-align: center;
}

#cardLeft {
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;
}

#topImage {
  display: block;
}

#cardLeftMiddle {
  margin: 10px 0;
}

#cardLeftBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 75%;
}

.logoContainer {
  margin: 1px;
}

.socialLink {
  text-decoration: none;
  color: inherit;
}

#cardRight {
  display: none;
}

@media screen and (min-width: 768px) {
  #cardLeft {
    padding-right: 12px;
    margin-right: 12px;
  }
  #topImage {
    display: none;
  }
  #cardRight {
    display: block;
    padding-left: 12px;
    margin-left: 12px;
  }
  .mainContent {
    margin: 10px 10%;
  }
  .card {
    padding: 15px 25px;
    min-height: 364px;
    font-size: larger;
    font-weight: lighter;
    min-width: 45vw;
    max-width: 50vw;
  }
}
